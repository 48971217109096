import { useState, useContext, useEffect } from "react";

import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from "../contexts/AuthStore";
import { makeStyles } from "@material-ui/core/styles";

import RegisterForm from "../components/users/RegisterForm";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import GoogleIcon from '@mui/icons-material/Google';
import Button from "@mui/material/Button";

import backgroundImage from "./login/background.jpg";

const socialLoginUrl = `${process.env.REACT_APP_API_BASE_URL}/authenticate/google`;


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    minHeight: "100vh",
    minWidth: "100vw",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2),
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  heading1: {
    textAlign: "center",
    fontWeight: "300",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  heading2: {
    textAlign: "center",
    fontWeight: "200",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  bodyText: {
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
}));





const LoginError = () => {
  const location = useLocation();
  return location.state && location.state.error ? (
    <p>{location.state.error}</p>
  ) : null;
};
const useQuery = () => new URLSearchParams(useLocation().search);

export default function Register() {
  const classes = useStyles();
  const { onUserChange } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const [showTerms, setShowTerms] = useState(false);
  const [conditions, setConditions] = useState("");
  const { user } = useContext(AuthContext);
  const [isValidDomain, setIsValidDomain] = useState(false);

  useEffect(() => {
    setIsValidDomain(process.env.REACT_APP_BASE_URL === window.location.hostname);
  }, []);

  useEffect(() => {
    fetch("/condiciones.txt")
      .then((response) => response.text())
      .then((text) => setConditions(text));
  }, []);

  useEffect(() => {
    if (user?.role === "admin") {
      history.push("/home");
    } else if (user?.role === "client") {
      history.push("/portal");
    }
  }, [user, history]);

  useEffect(() => {
    const newUser = query.get("newUser");
    if (newUser === "true") {
      // Fetch user data or update state as needed
      fetchUserData();
    }
  }, [query]);



  const fetchUserData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/me`, {
        credentials: 'include'
      });
      if (response.ok) {
        const userData = await response.json();
        onUserChange(userData);
        history.push("/portal");
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };




  const handleShowTerms = () => {
    setShowTerms(!showTerms);
  };



  


  return (
    <>
      <Dialog
        open={showTerms}
        onClose={() => setShowTerms(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Condiciones de uso - Terms of use</DialogTitle>
        <DialogContent>
          <p className="whiteSpace">{conditions}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTerms(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Typography
              component="h1"
              variant="h3"
              className={classes.heading1}
            >
              menorca advance
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              className={classes.heading2}
            >
              palliser law firm
            </Typography>
            <Typography
              variant="body1"
              className={`${classes.bodyText} mb-5 mt-5`}
            >
              Welcome to <strong>menorca advance</strong> &{" "}
              <strong>palliser law firm's</strong> online portal. Please
              register to access our range of services and resources.
            </Typography>
            <div className={classes.form}>
              <RegisterForm handleShowTerms={handleShowTerms} />
              <hr />
              <p>You can register with your email and password.</p>
              <p>
                The system will require you to provide a one-time access code in
                addition to your credentials. This two-factor authentication
                adds an extra layer of security to your account.
              </p>
              <p>
                <strong>
                  Please note that by registering, you agree to our Data
                  Protection and Legal Notices.
                </strong>
              </p>
              <a className="text-dark" href="https://palliser.es/">
                palliser.es
              </a>{" "}
              <a
                className="text-dark f100"
                style={{ fontSize: "0.8rem" }}
                href="https://palliser.es/terms"
              >
                Data protection and legal notices
              </a>
            </div>

            {/* <Button 
              variant="danger" 
              href={socialLoginUrl} 
              className="d-flex align-items-center"
               disabled={!isValidDomain}
            >
              <GoogleIcon className="me-2" style={{fill: 'white'}}/>
              Sign in with Google
            </Button> */}


            <div className={classes.google}>
              <LoginError />
            </div>
            <Box mt={10}>
              <div className="d-flex justify-content-center">
                <a
                  className=""
                  style={{ textDecoration: "none" }}
                  href="https://palliser.es"
                >
                  PALLISER LAW FIRM
                </a>{" "}
                <span className="ps-1" style={{ color: "#6c757d" }}>
                  {new Date().getFullYear()}
                </span>
              </div>
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
