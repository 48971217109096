import { useState } from "react";
import { useHistory } from "react-router";
import { register } from "../../services/users-service";
import Fab from "@mui/material/Fab";
import SaveIcon from "@mui/icons-material/Save";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_PATTERN = /^.{8,}$/;

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const validations = {
  name: (value) => {
    let message;
    if (!value) {
      message = "An user first name is required";
    }
    return message;
  },
  surname: (value) => {
    let message;
    if (!value) {
      message = "A surname is required";
    }
    return message;
  },
  email: (value) => {
    let message;
    if (!value) {
      message = "A valid email is required";
    } else if (!EMAIL_PATTERN.test(value)) {
      message = "The email is invalid";
    }
    return message;
  },
  password: (value) => {
    let message;
    if (!value) {
      message = "A valid password is required";
    } else if (!PASSWORD_PATTERN.test(value)) {
      message = "The password is invalid";
    }
    return message;
  },
  confirmPassword: (value) => {
    let message;
    if (value !== state.user.password) {
      message = "The passwords do not match";
    }
    return message;
  },
  acceptTerms: (value) => {
    let message;
    if (!value) {
      message = "You must accept the terms of use";
    }
    return message;
  },
};

function RegisterForm(props) {
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    user: {
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordConfirm: "",
      acceptTerms: false,
    },
    errors: {
      name: validations.name(),
      surname: validations.surname(),
      email: validations.email(),
      password: validations.password(),
      passwordConfirm: null,
      passwordMatch: null,
      acceptTerms: validations.acceptTerms(),
    },
    touch: {},
  });

  const isValid = () => {
    const { errors, user } = state;
    return (
      !Object.keys(errors).some((error) => errors[error]) &&
      user.password === user.passwordConfirm &&
      user.acceptTerms === true
    );
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    setState((state) => ({
      ...state,
      touch: {
        ...state.touch,
        [name]: true,
      },
    }));
  };

  const handleChange = (event) => {
    const { name, type } = event.target;
    const value =
      type === "checkbox" ? event.target.checked : event.target.value;
    const newValue = name === "email" ? value.toLowerCase() : value;

    setState((state) => ({
      ...state,
      user: {
        ...state.user,
        [name]: newValue,
      },
      errors: {
        ...state.errors,
        [name]: validations[name] && validations[name](newValue),
        passwordMatch:
          name === "password" &&
          state.user.passwordConfirm &&
          state.user.passwordConfirm !== value
            ? "Passwords do not match"
            : null,
        passwordConfirm:
          name === "passwordConfirm" &&
          state.user.password &&
          state.user.password !== value
            ? "Passwords do not match"
            : null,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
      try {
        const { user } = state;
        await register(user);
        window.alert(
          "Thank you for registering with Palliser. Your account is now active and you can login to palliser.app. For security reasons, you will receive a code via email each time you access the platform. Please remember to check your email and enter the code when prompted."
        );
        history.push("/login");
        if (props.handleRefresh) {
          props.handleRefresh();
        }
        setState((state) => ({
          user: {
            name: "",
            email: "",
            password: "",
            passwordConfirm: "",
            acceptTerms: false,
          },
          errors: {},
          touch: {},
        }));
      } catch (error) {
        console.error("Registration error:", error);
        let errorMessage =
          "An error occurred during registration. Please try again.";
        let errorDetails = {};

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          errorMessage = error.response.data.message || errorMessage;
          errorDetails = error.response.data.errors || {};
        } else if (error.request) {
          // The request was made but no response was received
          errorMessage =
            "No response received from server. Please check your internet connection.";
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = error.message;
        }

        setState((state) => ({
          ...state,
          errors: {
            ...state.errors,
            ...errorDetails,
            general: errorMessage,
          },
        }));

        window.alert(errorMessage);
      }
    }
  };

  const { user, errors, touch } = state;

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        autoComplete="name"
        autoFocus
        value={user.name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touch.name && Boolean(errors.name)}
        helperText={touch.name && errors.name}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="surname"
        label="Surname"
        name="surname"
        autoComplete="family-name"
        value={user.surname}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touch.surname && Boolean(errors.surname)}
        helperText={touch.surname && errors.surname}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        value={user.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touch.email && Boolean(errors.email)}
        helperText={touch.email && errors.email}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        value={user.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touch.password && Boolean(errors.password)}
        helperText={touch.password && errors.password}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="passwordConfirm"
        label="Confirm Password"
        type="password"
        id="passwordConfirm"
        autoComplete="new-password"
        value={user.passwordConfirm}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          touch.passwordConfirm &&
          (Boolean(errors.passwordConfirm) || Boolean(errors.passwordMatch))
        }
        helperText={
          touch.passwordConfirm &&
          (errors.passwordConfirm || errors.passwordMatch)
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={user.acceptTerms}
            onChange={handleChange}
            name="acceptTerms"
            color="primary"
          />
        }
        label={
          <span>
            I accept the{" "}
            <a href="#" onClick={props.handleShowTerms}>
              <b>terms of use</b>
            </a>
            .
          </span>
        }
      />
      {state.errors.general && (
        <p className="text-danger">{state.errors.general}</p>
      )}

      <div className="d-flex justify-content-center mt-5">
        <Fab
          style={{ backgroundColor: `#1568b9` }}
          size="medium"
          type="submit"
          disabled={!isValid()}
        >
          <SaveIcon style={{ fill: "white" }} />
        </Fab>
      </div>
    </form>
    
  );
}

export default RegisterForm;
