// AuthStore.js

import { createContext, useState, useCallback } from 'react';

import { currentUserStorageKey } from '../services/base-api-service';

const AuthContext = createContext();

function AuthStore({ children }) {

  const [user, setUser] = useState(localStorage.getItem(currentUserStorageKey) ? JSON.parse(localStorage.getItem(currentUserStorageKey)) : undefined);

  const handleUserChange = useCallback((user) => {
    if (user) localStorage.setItem(currentUserStorageKey, JSON.stringify(user));
    else localStorage.removeItem(currentUserStorageKey);
    setUser(user);
  }, []);

  const isAuthenticated = useCallback(() => {
    return user && user.email;
  }, [user])

  const isAdmin = useCallback(() => {
    if (user?.role === "admin" ) {return user && user.email} else {return false}
  }, [user])

  const isAuthDomain = useCallback(() => {
    if (user?.email.includes("@palliser.es")) {return user && user.email} else {return false}
  }, [user])

  const isUser = useCallback(() => {
    if (user?.role === "user" ) {return true} else {return false}
  }, [user])

  const isExternal = useCallback(() => {
    if (user?.role === "external" ) {return true} else {return false}
  }, [user])

  const isClient = useCallback(() => {
    if (user?.role === "client" ) {
      return user && user.email} else {return false}
  }, [user])


  return (
    <AuthContext.Provider value={{ user, isAuthenticated, isAdmin, isAuthDomain, isUser, isExternal, isClient, onUserChange: handleUserChange }} >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthStore as default, AuthContext };

