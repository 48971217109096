import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './index.css';

import App from './App';

ReactDOM.render(
  <div className={localStorage.getItem('userFont')}>
    <App/>
  </div>,
  document.getElementById('root')
);

