// AdminRoute.js

import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthStore';

function AdminRouter({ component: Component, ...routeProps}) {
  const { user, isAuthenticated, isAdmin, isAuthDomain, isUser, isExternal, isClient, onUserChange } = useContext(AuthContext);
  return (
    <Route {...routeProps} component={(componentProps => {
      
      if (isAdmin() && user?.email.includes("@palliser.es")) return <Component {...componentProps} 
      /> 
       else if (isClient()) return <Redirect to="/portal"/>
       else  return <Redirect to="/login"/>
    }) } />
  )
}

export default AdminRouter;
