//LoginMUI.jsx

import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Fab from "@mui/material/Fab";
import MailLockIcon from "@mui/icons-material/MailLock";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SignInIcon from "@mui/icons-material/VpnKey";
import Tooltip from "@mui/material/Tooltip";




import { AuthContext } from "../../contexts/AuthStore";
import { code, login } from "../../services/users-service";

import backgroundImage from "./background.jpg";
import "./login.css";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    minHeight: '100vh',
    minWidth: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#fff",
  },
  google: {
    margin: theme.spacing(2, 0),
  },
  heading1: {
    textAlign: "center",
    fontWeight: "300",
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  heading2: {
    textAlign: "center",
    fontWeight: "200",
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  bodyText: {
    textAlign: "justify",
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),
  },
  iconButton: {
    margin: theme.spacing(1),
  },
}));


export default function SignInSide() {
  const classes = useStyles();
  const [codeSent, setCodeSent] = useState(false);
  const { onUserChange } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const [userAccess, setUserAccess] = useState(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    setUserAccess(
      !(
        hostname === "app.palliser.dev" ||
        hostname === "localhost" ||
        hostname === "palliser.dev"
      )
    );
  }, []);

  const [state, setState] = useState({
    user: {
      email: location.state?.email || "",
      password: "",
      totpSecret: "",
    },
    errors: {},
    verificationError: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === "email" ? value.toLowerCase() : value;

    setState((state) => ({
      ...state,
      user: {
        ...state.user,
        [name]: newValue,
      },
      errors: {},
    }));
  };

  const handleReset = () => {
    setCodeSent(false);
    setState((state) => ({
      ...state,
      user: {},
      errors: {},
    }));
  };

  const handleSubmitCode = async (event) => {
    event.preventDefault();
    if (!emailRegex.test(state.user.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      await code(state.user.email, state.user.password);
      alert("Please check your email for the code.");
      setCodeSent(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert(
          "Incorrect username or password; please try again or contact us if you need help."
        );
      } else {
        const errorMessage = error.response
          ? error.response.data.message
          : "Ocurrió un error";
        alert(errorMessage);
      }
    }
  };

  const handleSubmitFinal = async (event) => {
    event.preventDefault();

    try {
      const user = await login(
        state.user.email,
        state.user.password,
        state.user.totpSecret
      );
      onUserChange(user);
      history.push("home");
    } catch (error) {
      if (error.response.status === 429) {
        history.push("/login", {
          error: "Too many login attempts, try again later",
        });
      } else {
        const { message, errors } = error.response
          ? error.response.data
          : error;
        setState((state) => ({
          ...state,
          errors: errors,
        }));
      }
    }
  };

  const { user, errors } = state;

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h3"
            className={classes.heading1}
          >
            menorca advance
          </Typography>
          <Typography
            component="h2"
            variant="h4"
            className={classes.heading2}
          >
            palliser law firm
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.bodyText} mb-5 mt-5`}
          >
            Welcome to <strong>menorca advance</strong> &{" "}
            <strong>palliser law firm's</strong> online portal. Please log in to
            access our range of services and resources.
          </Typography>

    


          <form
            className={classes.form}
            onSubmit={codeSent ? handleSubmitFinal : handleSubmitCode}
          >
            {!codeSent && (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange}
                  value={user.email}
                  error={!!errors.email}
                  helperText="Email is required"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  value={user.password}
                />
              </>
            )}
            {codeSent && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="totpSecret"
                label="Check your email and insert the code"
                name="totpSecret"
                autoComplete="off"
                autoFocus
                onChange={handleChange}
                value={user.totpSecret}
                helperText="Access code is required, check your email"
              />
            )}
            <div className={classes.iconContainer}>
              {!codeSent ? (
                <Tooltip title="Sign in with your email and password">
                  <Fab
                    color="primary"
                    size="medium"
                    disabled={!user.email || !user.password}
                    onClick={handleSubmitCode}
                    className={classes.iconButton}
                  >
                    <MailLockIcon style={{ fill: "white" }} />
                  </Fab>
                </Tooltip>
              ) : (
                <Fab
                  color="primary"
                  size="medium"
                  onClick={handleSubmitFinal}
                  disabled={!user.totpSecret}
                  className={classes.iconButton}
                >
                  <SignInIcon style={{ fill: "white" }} />
                </Fab>
              )}
              {codeSent && (
                <Tooltip title="Send another code">
                  <Fab
                    color="primary"
                    size="medium"
                    onClick={handleReset}
                    className={classes.iconButton}
                  >
                    <RotateLeftIcon style={{ fill: "white" }} />
                  </Fab>
                </Tooltip>
              )}
            </div>
            <Box mt={10}>
              <div className="d-flex justify-content-center">
                <a
                  className=""
                  style={{ textDecoration: "none" }}
                  href="https://palliser.es"
                >
                  palliser menorca
                </a>
                <span className="ps-1" style={{ color: "#6c757d" }}>
                  {new Date().getFullYear()}
                </span>
              </div>
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
  
}
