// LinkAuthContext.js
import React, { createContext, useState, useContext } from 'react';

const LinkAuthContext = createContext();

export function LinkAuthProvider({ children }) {
  const [isLinkAuthenticated, setLinkAuthenticated] = useState(
    localStorage.getItem('isLinkAuthenticated') === 'true'
  );
  const [linkId, setLinkId] = useState(localStorage.getItem('linkId'));

  const authenticateLinkUser = (id) => {
    localStorage.setItem('isLinkAuthenticated', 'true');
    localStorage.setItem('linkId', id);
    setLinkAuthenticated(true);
    setLinkId(id);
  };

  const logoutLinkUser = () => {
    localStorage.removeItem('isLinkAuthenticated');
    localStorage.removeItem('linkId');
    setLinkAuthenticated(false);
    setLinkId(null);
  };

  return (
    <LinkAuthContext.Provider value={{ isLinkAuthenticated, linkId, authenticateLinkUser, logoutLinkUser }}>
      {children}
    </LinkAuthContext.Provider>
  );
}

export function useLinkAuth() {
  return useContext(LinkAuthContext);
}

