// App.js

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthStore from "./contexts/AuthStore";
import { LinkAuthProvider } from "./contexts/LinkAuthContext";
import UnauthenticatedRoute from "./guards/UnauthenticatedRoute";
import ClientRoute from "./guards/ClientRoute";
import AdminRoute from './guards/AdminRoute';

import AuthCallback from "./screens/AuthCallback";

import Register from "../src/screens/Register";
import Login from "./screens/login/LoginMUI";
import Home from "./screens/home/Home";

import "./index.css";

import Error from "./screens/Error";

console.log()
function App() {
  return (
    <Router>
      <AuthStore>
        <LinkAuthProvider>
          <Switch>
            {/* PUBLIC ROUTES */}
            <Route exact path="/authenticate/google/cb" component={AuthCallback} />

            <Route exact path="/register" component={Register} />
            

            {/* CLIENT ROUTES  */}
            <ClientRoute path="/portal" component={Home} />
            <ClientRoute path="/home" component={Home} />

            
            <Route exact path="/404" component={() => <Error code={404} />} />
            <Route exact path="/403" component={() => <Error code={403} />} />
            <UnauthenticatedRoute path="/" component={Login} />
            <Route path="*" component={() => <Error code={404} />} />
          </Switch>
        </LinkAuthProvider>
      </AuthStore>
    </Router>
  );
}

export default App;
