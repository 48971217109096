// IconComponent.jsx

import React from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import 'bootstrap-icons/font/bootstrap-icons.css';


export function getIcon(documentType) {

    if (!documentType) {
        return <ArticleIcon style={{ fill: '#37352F', width: 20, height: 20 }} />;
    }

    const lowerCaseDocumentType = documentType.toLowerCase();

    if (lowerCaseDocumentType.includes('jpeg') ||
        lowerCaseDocumentType.includes('jpg') ||
        lowerCaseDocumentType.includes('webp')) {
            return <i className="bi bi-file-image" style={{ color: '#d50000', fontSize: '20px' }}></i>;
    }

    
    else if (
        lowerCaseDocumentType.includes('png')) {
            return <i className="bi bi-file-image" style={{ color: '#d50000', fontSize: '20px' }}></i>;
    }
    else if (
        lowerCaseDocumentType.includes('pdf')) {
        return <i className="bi bi-file-earmark-pdf-fill"
    style={{ color: '#CB0606', fontSize: '20px' }}
        ></i>;

    }
    else if (lowerCaseDocumentType.includes('word')) {
        return <i className="bi bi-file-word-fill" style={{ color: '#1854B4', fontSize: '20px' }}></i>;
    }
    else if (
        // lowerCaseDocumentType.includes('txt') ||
        lowerCaseDocumentType.includes('plain')) {
            return <i className="bi bi-filetype-txt" style={{ color: '#121212', fontSize: '20px' }}></i>;
        }
    else if (
        lowerCaseDocumentType.includes('video') ||
        lowerCaseDocumentType.includes('mp4') ||
        lowerCaseDocumentType.includes('mpeg')) {
        return <i className="bi bi-film" style={{ color: '#00695c', fontSize: '20px' }}></i>;

    }
    else if (
        lowerCaseDocumentType.includes('excel')) {
        return <i className="bi bi-file-excel-fill" style={{ color: '#217346', fontSize: '20px' }}></i>;
    }
    else if (
        lowerCaseDocumentType.includes('sheet')) {
        return <i className="bi bi-file-earmark-spreadsheet" style={{ color: '#217346', fontSize: '20px' }}></i>;
    }
    else if (
        lowerCaseDocumentType.includes('csv')) {
        return <i className="bi bi-filetype-csv" style={{ color: '#217346', fontSize: '20px' }}></i>;
    }
    else {
        return <ArticleIcon style={{ fill: '#37352F', width: 20, height: 20 }} />;
    }
}