// UnauthenticatedRoute.js

import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthStore';

function UnauthenticatedRoute({ component: Component, ...routeProps}) {
//   const { isAuthenticated } = useContext(AuthContext);
  const { user, isAuthenticated, isAdmin, isAuthDomain, isUser, isExternal, isClient, onUserChange } = useContext(AuthContext);

  return (
    <Route {...routeProps} component={(componentProps => {
      
      if (!isAuthenticated()) return <Component {...componentProps} 
      /> 
       else if (isClient()) return <Redirect to="/portal"/>
       else  return <Redirect to="/home"/>
    }) } />
  )
}

export default UnauthenticatedRoute;
