// clientRoute.js
import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthStore';

function AdminRouter({ component: Component, ...routeProps}) {
const { user, isAuthenticated, isAdmin, isUser, isExternal, isClient, onUserChange } = useContext(AuthContext);

  return (
    <Route {...routeProps} component={(componentProps => {
      if (isClient()) return <Component {...componentProps}/>
       else  return <Redirect to="/login"/>
    }) } />
  )
}

export default AdminRouter;
