import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthStore";
import { useInView } from "react-intersection-observer";
import Link from "@material-ui/core/Link";
import JSZip from "jszip";
import axios from "axios";
import { useParams } from "react-router";

import { Container, Row, Col, Card } from "react-bootstrap";
import moment from "moment";
import "./home.css"; // Ajuste en la ruta del CSS
import Cabecera from "./cabecera/cabecera";

import { getIcon } from "../../components/Icons/getIcon";
import { saveAs } from "file-saver";

// services
import ExternalsService from "../../services/externals-service";

// Media
import img1 from "../client-portal/covers/cover-1.jpeg";
import img2 from "../client-portal/covers/cover-2.jpeg";
import img3 from "../client-portal/covers/cover-3.jpeg";
import img4 from "../client-portal/covers/cover-4.jpeg";
import img5 from "../client-portal/covers/cover-5.jpeg";
import img6 from "../client-portal/covers/cover-6.jpeg";
import img7 from "../client-portal/covers/cover-7.jpeg";
import img8 from "../client-portal/covers/cover-8.jpeg";
import img9 from "../client-portal/covers/cover-9.jpeg";
import img10 from "../client-portal/covers/cover-10.jpeg";

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
const fileIdToImageMap = {};

const hashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const getImageForFileId = (fileId) => {
  const hash = hashCode(fileId);
  const index = Math.abs(hash) % images.length;
  return images[index];
};

const CustomerProfile = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();

  const { user } = useContext(AuthContext);
  const [updatedUser, setUpdatedUser] = useState();
  const [files, setFiles] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [actions, setActions] = useState([]);

  const [loading, setLoading] = useState(true);
  const [filterWord, setFilterWord] = useState("");
  const [filterMode, setFilterMode] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [activeFileIndex, setActiveFileIndex] = useState(999);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [currentDownload, setCurrentDownload] = useState(0); // Nuevo estado para rastrear el número de documentos que se están descargando

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  let placeholderText = "";
  switch (user.language) {
    case "en":
      placeholderText = `search for records & documents`;
      break;
    case "es":
      placeholderText = `buscar registros y documentos`;
      break;
    case "fr":
      placeholderText = `rechercher des enregistrements et des documents`;
      break;
    case "it":
      placeholderText = `cerca registrazioni e documenti`;
      break;
    default:
      placeholderText = `search for records & documents`;
  }

  useEffect(() => {
    let isUnmounted = false;

    async function fetchInfo() {
      setLoading(true);
      if (user.acceptTerms === true || user.role === "admin") {
        try {
          const files = await ExternalsService.userAccessFiles(id || user.id);
          const activat = await ExternalsService.checkUserAdminActivation();

          const allActions = files.flatMap((file) =>
            file.actions.map((action) => ({
              ...action,
              fileName: file.public_title,
            }))
          );
          const allDocuments = files.flatMap((file) =>
            file.actions.flatMap((action) => action.documents)
          );

          if (!isUnmounted) {
            const sortedActions = allActions.sort(
              (b, a) => new Date(a.DATA) - new Date(b.DATA)
            );
            setFiles(files);
            setTimeline(sortedActions);
            setActions(sortedActions);
            setDocuments(allDocuments);
            setUpdatedUser(activat);

            sortedActions.forEach((action, index) => {
              if (!fileIdToImageMap[action.file_id] && index < images.length) {
                fileIdToImageMap[action.file_id] = images[index];
              }
            });
          }
        } catch (error) {
          if (!isUnmounted) {
            if (error.response?.status === 404) {
              history.push("/");
            } else if (error.response?.status === 503) {
              console.log("User not activated");
              setUpdatedUser(false);
            } else {
              console.error(error);
            }
          }
        } finally {
          if (!isUnmounted) {
            setLoading(false);
          }
        }
      } else {
        if (!isUnmounted) {
          setLoading(false);
        }
      }
    }

    fetchInfo();

    return () => {
      isUnmounted = true;
    };
  }, [user, id]);




  const handleChangeFilter = (e) => {
    e.preventDefault();
    setFilterWord(e.target.value);

    if (filterWord.length > 0) {
      setFilterMode(true);
    }
  };

  const ActionCard = ({ action, index }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });
  
    return (
      <Card
        className={`mb-0 fade-in-card ${inView ? "visible" : ""}`}
        ref={ref}
        style={{
          border: "0px solid gray",
        }}
      >
        <Card.Body>
          <span className="f12">
            {moment(action.DATA).format("YYYY/MM/DD")} |{" "}
            {moment(action.DATA).fromNow()}
            <span style={{ float: "right" }}></span>
          </span>
          <p className="action-description">
            <div
              dangerouslySetInnerHTML={{
                __html: highlightFilterWord(action.public_title),
              }}
            />
          </p>
  
          {action.documents && action.documents.length > 0 && (
            <Card>
              <Card.Body>
                <div className="f100">
                  {action.documents
                    .filter(
                      (document) =>
                        !filterWord ||
                        document.title
                          .toLowerCase()
                          .includes(filterWord.toLowerCase())
                    )
                    .map((document, index) => (
                      <div key={index}>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "black",
                            cursor: "pointer",
                            display: "block", // Asegura que el enlace ocupe todo el ancho disponible
                          }}
                          href={`${baseUrl}/documents/${document.key}`}
                          target="_blank"
                        >
                          <div className="document-item mt-1 hover-document">
                            <span className="document-icon">
                              {getIcon(document?.document_type)}
                            </span>
                            <span className="document-title f09">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: highlightFilterWord(
                                    document.title.toUpperCase()
                                  ),
                                }}
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </Card.Body>
            </Card>
          )}
  
          {activeFileIndex === 999 && (
            <div className="image-text-container mt-4">
              <img
                className="cover-image"
                src={getImageForFileId(action.file_id)}
                alt="Cover"
              />
              <span className="file-name f09">
                {action.fileName?.toUpperCase()}
              </span>
            </div>
          )}
          <hr />
        </Card.Body>
      </Card>
    );
  };
  

  const highlightFilterWord = (text) => {
    const regex = new RegExp(`(${filterWord})`, "gi");
    return text.replace(
      regex,
      '<span style="background-color: yellow; color: red;">$1</span>'
    );
  };

  const filteredActions = filterWord
    ? (activeFileIndex >= files.length
        ? timeline
        : files[activeFileIndex].actions
      ).filter(
        (item) =>
          item.public_title?.toLowerCase().includes(filterWord.toLowerCase()) ||
          item.public_description
            ?.toLowerCase()
            .includes(filterWord.toLowerCase()) ||
          item.tag_public?.toLowerCase().includes(filterWord.toLowerCase()) ||
          item.documents.some((doc) =>
            doc.title.toLowerCase().includes(filterWord.toLowerCase())
          )
      )
    : activeFileIndex >= files.length
    ? actions
    : files[activeFileIndex].actions;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const handleDownload = async () => {
    console.log("handleDownload");
    try {
      setDownloading(true);
      const zip = new JSZip();
      const documentsToDownload = documents
        .filter((item) => item.visibility === true)
        .map((item) => ({
          key: item.key,
          filename: item.title.includes(".")
            ? item.title
            : `${item.title}.${item.originalname?.split(".").pop()}`,
        }));

      const totalDocuments = documentsToDownload.length; // Total de documentos a descargar

      const filePromises = documentsToDownload.map(async (doc, index) => {
        const response = await axios({
          method: "GET",
          url: `${baseUrl}/documents/${doc.key}`,
          responseType: "blob",
          withCredentials: true,
          params: {
            download: "true", // Add the "download" parameter here
          },
        });

        const blob = new Blob([response.data], { type: response.data.type });
        zip.file(doc.filename, blob); // Ahora doc.filename incluye la extensión del archivo

        setCurrentDownload(index + 1); // Actualiza el estado con el número del documento que se está descargando
      });

      await Promise.all(filePromises);

      const zipBlob = await zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
          level: 9,
        },
      });

      saveAs(
        zipBlob,
        `${new Date().toISOString().slice(0, 10).replaceAll("-", "")}.zip`
      );
      setShowModal(false);
      setDownloading(false);
      setCurrentDownload(0); // Restablece el estado a 0 después de que todas las descargas se hayan completado
    } catch (error) {
      console.error(error);
      setDownloading(false);
    }
  };

  // Textes ==================================================

  const getIntroductionText = () => {
    if (user.language === "es") {
      return "Usted ha accedido a su área personal de Palliser Law Firm y Menorca.me. En la misma podrá encontrar toda la información relacionada con los servicios que tiene contratados con nosotros. Para cualquier duda, no dude en contactar con nosotros.";
    } else if (user.language === "fr") {
      return "Vous avez accédé à votre espace personnel de Palliser Law Firm et Menorca.me. Vous y trouverez toutes les informations relatives aux services que vous avez contractés avec nous. Pour toute question, n'hésitez pas à nous contacter.";
    } else if (user.language === "it") {
      return "Hai acceduto alla tua area personale di Palliser Law Firm e Menorca.me. Qui puoi trovare tutte le informazioni relative ai servizi che hai contrattato con noi. Per qualsiasi dubbio, non esitare a contattarci.";
    } else {
      return "You have accessed your personal area of Palliser Law Firm and Menorca.me. Here you can find all the information related to the services you have contracted with us. For any questions, do not hesitate to contact us.";
    }
  };

  const getDescriptionText = (user) => {
    return (
      <p
        className="search-description mt-2 f300 bg-white"
        style={{ fontSize: "1.1rem" }}
      >
        {user.language === "es" && (
          <span>
            En cada expediente, podrá encontrar la{" "}
            <strong>lista de registros</strong> que se han realizado, así como
            la <strong>documentación</strong> generada.
          </span>
        )}
        {user.language === "fr" && (
          <span>
            Dans chaque dossier, vous pourrez trouver la{" "}
            <strong>liste des enregistrements</strong> qui ont été effectués,
            ainsi que la <strong>documentation</strong> générée.
          </span>
        )}
        {user.language === "it" && (
          <span>
            In ogni fascicolo, sarai in grado di trovare la{" "}
            <strong>lista dei record</strong> che sono stati effettuati, così
            come la <strong>documentazione</strong> generata.
          </span>
        )}
        {(!user.language ||
          (user.language !== "es" &&
            user.language !== "fr" &&
            user.language !== "it")) && (
          <span>
            In each file, you will be able to find the{" "}
            <strong>list of records</strong> that have been carried out, as well
            as the <strong>documentation</strong> generated.
          </span>
        )}
      </p>
    );
  };

  const getSearchDescriptionText = (user) => {
    return (
      <p
        className="search-description mt-2 f300 text-left bg-white"
        style={{ fontSize: "1.1rem" }}
      >
        {user.language === "es" && (
          <span>
            Realice una búsqueda exhaustiva en todo el{" "}
            <strong>portal del cliente</strong> para encontrar documentos y
            registros relacionados con su propiedad y servicios.
          </span>
        )}
        {user.language === "fr" && (
          <span>
            Effectuez une recherche approfondie sur l'ensemble du{" "}
            <strong>portail client</strong> pour trouver des documents et des
            enregistrements liés à votre propriété et à vos services.
          </span>
        )}
        {user.language === "it" && (
          <span>
            Eseguire una ricerca approfondita in tutto il{" "}
            <strong>portale del cliente</strong> per trovare documenti e
            registrazioni relative alla tua proprietà e servizi.
          </span>
        )}
        {(!user.language ||
          (user.language !== "es" &&
            user.language !== "fr" &&
            user.language !== "it")) && (
          <span>
            Perform a comprehensive search across the{" "}
            <strong>entire client portal</strong> to find documents and records
            related to your property and services.
          </span>
        )}
      </p>
    );
  };
  const timelineTitle = (user) => {
    return (
      <>
        {user.language === "es" && <span>Cronología del expediente</span>}
        {user.language === "fr" && <span>Chronologie du dossier</span>}
        {user.language === "it" && <span>Cronologia del fascicolo</span>}
        {(!user.language ||
          (user.language !== "es" &&
            user.language !== "fr" &&
            user.language !== "it")) && <span>File timeline</span>}
      </>
    );
  };

  const timelineDescription = (user) => {
    return (
      <>
        {user.language === "es" && (
          <span>
            En esta sección, usted podrá encontrar todos los registros y
            actuaciones de todos los expedientes abiertos con nosotros,
            ordenados por fecha. Puede elegir un expediente específico en la
            selección de expedientes activos.
          </span>
        )}
        {user.language === "fr" && (
          <span>
            Dans cette section, vous pourrez trouver tous les enregistrements et
            actions de tous les dossiers ouverts avec nous, classés par date.
            Vous pouvez choisir un dossier spécifique dans la sélection des
            dossiers actifs.
          </span>
        )}
        {user.language === "it" && (
          <span>
            In questa sezione, potrai trovare tutti i registri e le azioni di
            tutti i fascicoli aperti con noi, ordinati per data. Puoi scegliere
            un fascicolo specifico nella selezione dei fascicoli attivi.
          </span>
        )}
        {(!user.language ||
          (user.language !== "es" &&
            user.language !== "fr" &&
            user.language !== "it")) && (
          <span>
            In this section, you will find all records and actions of all open
            files with us, sorted by date. You can choose a specific file in the
            active files selection.
          </span>
        )}
      </>
    );
  };

  const documentListTitle = (user) => {
    return (
      <>
        {user.language === "es" && (
          <span>
            <i className="bi bi-archive"></i> TODOS LOS DOCUMENTOS
          </span>
        )}
        {user.language === "fr" && (
          <span>
            <i className="bi bi-archive"></i> TOUS LES DOCUMENTS
          </span>
        )}
        {user.language === "it" && (
          <span>
            <i className="bi bi-archive"></i> TUTTI I DOCUMENTI
          </span>
        )}
        {(!user.language ||
          (user.language !== "es" &&
            user.language !== "fr" &&
            user.language !== "it")) && (
          <span>
            <i className="bi bi-archive"></i> ALL DOCUMENTS
          </span>
        )}
      </>
    );
  };

  const documentsListDescription = (user) => {
    return (
      <>
        {user.language === "es" && (
          <span>
            En esta sección, usted tiene a su disposición todos los{" "}
            <strong>documentos relevantes</strong>, tanto{" "}
            <strong>oficiales</strong> como de <strong>interés personal</strong>
            , generados en el curso de cada uno de sus expedientes.
          </span>
        )}
        {user.language === "fr" && (
          <span>
            Dans cette section, vous avez à votre disposition tous les{" "}
            <strong>documents pertinents</strong>, qu'ils soient{" "}
            <strong>officiels</strong> ou d'<strong>intérêt personnel</strong>,
            générés au cours de chacun de vos dossiers.
          </span>
        )}
        {user.language === "it" && (
          <span>
            In questa sezione, hai a tua disposizione tutti i{" "}
            <strong>documenti rilevanti</strong>, sia <strong>ufficiali</strong>{" "}
            che di <strong>interesse personale</strong>, generati nel corso di
            ciascuno dei tuoi fascicoli.
          </span>
        )}
        {(!user.language ||
          (user.language !== "es" &&
            user.language !== "fr" &&
            user.language !== "it")) && (
          <span>
            In this section, you have at your disposal all the{" "}
            <strong>relevant documents</strong>, both <strong>official</strong>{" "}
            and of <strong>personal interest</strong>, generated during the
            course of each of your files.
          </span>
        )}
      </>
    );
  };

  console.log(files);
    return (
    <Container className="mt-3">
      <Cabecera />
      {loading ? (
        <div className="text-center mt-5">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-2">Loading your personalized client suite...</p>
        </div>
      ) : updatedUser === null ? (
        <div className="text-center mt-5">
          <p>An error occurred while loading your data. Please try again later.</p>
        </div>
      ) : updatedUser !== true ? (
        <div>
          <div style={{ maxWidth: "100vh" }}>
            <div className="ms-5" style={{ marginBottom: "100px" }}>
              <div
                className={``}
                style={{
                  fontSize: "1.5rem",
                  minWidth: "10px",
                }}
              >
                <span className={`f600 text-gray-fort pe-5 `}>
                  Pending Activation of Your Personalized Client Suite
                </span>
              </div>

              <div
                className="text-gray mt-1 pe-5 justify"
                style={{ fontSize: "1rem" }}
              >
                <p>
                  Dear client, we are delighted to welcome you to the{" "}
                  <strong>Menorca Advance & Palliser Law Firm's Portal</strong>.
                  We are currently preparing your{" "}
                  <strong>personalized client suite</strong>, meticulously
                  designed to cater to your unique needs. Please note, this
                  activation process might take some time as we strive to ensure
                  utmost security and efficiency of our services.
                </p>
                <p>
                  Once your client suite is ready, we will promptly notify you.
                  We greatly appreciate your{" "}
                  <strong>patience and understanding</strong> during this time.
                  Thank you for choosing Menorca Advance & Palliser Law Firm.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Row>
          <Col md={4} className="left-column">
            {/* INICI PRESENTACIO */}

            <div className="search-card">
              <h1 className="search-title">
                {user.language === "es" &&
                  `bienvenido, ${
                    files[0]?.user_access.name?.toLowerCase() ||
                    user.name?.toLowerCase()
                  }`}
                {user.language === "fr" &&
                  `bienvenue, ${
                    files[0]?.user_access.name?.toLowerCase() ||
                    user.name?.toLowerCase()
                  }`}
                {user.language === "it" &&
                  `benvenuto, ${
                    files[0]?.user_access.name?.toLowerCase() ||
                    user.name?.toLowerCase()
                  }`}
                {(!user.language ||
                  (user.language !== "es" &&
                    user.language !== "fr" &&
                    user.language !== "it")) &&
                  `welcome, ${
                    files[0]?.user_access.name?.toLowerCase() ||
                    user.name?.toLowerCase()
                  }${user.surname ? ` ${user.surname.toLowerCase()}` : ""}`}
              </h1>

              <hr className="search-divider" />

              <p className="search-description">{getIntroductionText()}</p>
            </div>

            {/* END PRESENTACIO */}

            {/* FILES SECTION */}

            <div className="search-card">
              <h1 className="search-title">
                {user.language === "es" && (
                  <span className="">Expedientes activos</span>
                )}
                {user.language === "fr" && (
                  <span className="">Dossiers actifs</span>
                )}
                {user.language === "it" && (
                  <span className="">Fascicoli attivi</span>
                )}
                {(!user.language ||
                  (user.language !== "es" &&
                    user.language !== "fr" &&
                    user.language !== "it")) && (
                  <span className="">Active files</span>
                )}
              </h1>

              <hr className="search-divider" />
              <p className="search-description">{getDescriptionText(user)}</p>

              {/* FILES LIST */}

              <div
                className="my-2 py-1"
                onClick={() => setActiveFileIndex(999)}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    activeFileIndex === 999 ? "black" : "transparent",
                  color: activeFileIndex === 999 ? "white" : "black",
                  textDecoration: "none",
                }}
              >
                <span className="file-name f09">
                  <i className="bi bi-calendar2-week"></i> TIMELINE
                </span>
              </div>
              {files?.map((file, index) => (
                <div
                  className="my-2 py-1"
                  onClick={() => setActiveFileIndex(index)}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      activeFileIndex === index ? "#000000" : "transparent",
                    color: activeFileIndex === index ? "#FFFFFF" : "#000000",
                    textDecoration: "none",
                  }}
                >
                  <span className="file-name f09">
                    <i className="bi bi-folder"></i>{" "}
                    {file.public_title.toUpperCase()}
                  </span>
                </div>
              ))}

              <div
                className="my-2 py-1"
                onClick={() => setActiveFileIndex(888)}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    activeFileIndex === 888 ? "black" : "transparent",
                  color: activeFileIndex === 888 ? "#FFFFFF" : "#000000",
                  textDecoration: "none",
                }}
              >
                <span className="file-name f09">
                  {documentListTitle(user)} {documents.length}
                </span>
              </div>
            </div>

            {/* END FILES SECTION */}

            {/* SEARCH SECTION  */}

            {filterMode && files?.length > 0 && (
              <div className={` pt-5 pb-6 `}>
                <div className="search-card">
                  <h1 className="search-title">
                    {user.language === "es"
                      ? "Buscar"
                      : user.language === "fr"
                      ? "Rechercher"
                      : user.language === "it"
                      ? "Cerca"
                      : "Search"}
                  </h1>

                  <hr className="search-divider" />
                  <p className="search-description">
                    {getSearchDescriptionText(user)}
                  </p>
                </div>

                {/* END SEARCH SECTION */}

                <form
  className="sticky-search-form"
  autoComplete="off"
>
  <div
    className="input-group container-fluid sticky-search"
  >
    <input
      type="text"
      className="form-control border border-dark bg-white autoFocus"
      name="searchText"
      autoComplete="off"
      value={filterWord}
      onChange={handleChangeFilter}
      placeholder={placeholderText}
    />
    {filterWord && (
      <button
        type="button"
        className="btn btn-outline-dark"
        onClick={() => {
          setFilterWord("");
          setFilterMode(true);
        }}
      >
        X
      </button>
    )}
  </div>
</form>

              </div>
            )}
          </Col>

          {/* COLUMNA DRETA
           */}
          <Col md={8} className="right-column">
            <div className="right-card">
              <h1 className="right-title">
                {activeFileIndex === 888
                  ? documentListTitle(user)
                  : activeFileIndex === 999
                  ? timelineTitle(user)
                  : capitalizeFirstLetter(files[activeFileIndex].public_title)}
              </h1>
              <hr className="right-divider" />

              <p className="right-description">
                {activeFileIndex === 999
                  ? timelineDescription(user)
                  : activeFileIndex === 888
                  ? documentsListDescription(user)
                  : // : files[activeFileIndex].public_description}
                    ""}
              </p>
            </div>
            {activeFileIndex !== 888 && (
              <div>
                {filteredActions.map((action, index) => (
                  <ActionCard key={index} action={action} index={index} />
                ))}
              </div>
            )}

            {/* LLISTAT DE DOCUMENTS PER DESCARREGA */}
            {activeFileIndex === 888 && documents.length > 0 && (
              <Card>
                <Card.Body>
                  <div className="f100">
                    {documents
                      .filter(
                        (document) =>
                          !filterWord ||
                          document.title
                            .toLowerCase()
                            .includes(filterWord.toLowerCase())
                      )
                      .map((document, index) => (
                        <div key={index}>
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "black",
                              cursor: "pointer",
                              display: "block", // Asegura que el enlace ocupe todo el ancho disponible
                            }}
                            href={`${baseUrl}/documents/${document.key}`}
                            target="_blank"
                          >
                            <div className="document-item mt-1 hover-document">
                              <span className="document-icon">
                                {getIcon(document?.document_type)}
                              </span>
                              <span className="document-title f09">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: highlightFilterWord(
                                      document.title.toUpperCase()
                                    ),
                                  }}
                                />
                              </span>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </Card.Body>

                <div
                  className="my-9"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowModal(true)}
                  >
                    <i
                      className="bi bi-download"
                      style={{ color: "#ffffff" }}
                    ></i>{" "}
                    <span className="text-white">
                      {!user.language
                        ? "Download"
                        : user.language === "en"
                        ? "Download"
                        : user.language === "it"
                        ? "Scarica"
                        : user.language === "es"
                        ? "Descargar"
                        : user.language === "fr"
                        ? "Télécharger"
                        : "Download"}
                    </span>
                  </button>
                </div>
                <div style={{ marginTop: "20px" }}></div>
              </Card>
            )}
          </Col>
        </Row>
      )}

      {showModal && (
        <div
          className="modal"
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            marginTop: "60px",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title f600 f14">
                  <span>
                    {!user.language || user.language === "en"
                      ? "Download confirmation"
                      : user.language === "it"
                      ? "Conferma del download"
                      : user.language === "es"
                      ? "Confirmación de descarga"
                      : user.language === "fr"
                      ? "Confirmation de téléchargement"
                      : "Download confirmation"}
                  </span>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  disabled={downloading}
                ></button>
              </div>
              <div className="modal-body">
                {downloading ? (
                  <p>
                    {" "}
                    <span>
                      {!user.language || user.language === "en"
                        ? "Downloading documents..."
                        : user.language === "it"
                        ? "Scaricamento documenti..."
                        : user.language === "es"
                        ? "Descargando documentos..."
                        : user.language === "fr"
                        ? "Téléchargement des documents..."
                        : "Downloading documents..."}{" "}
                      {currentDownload}
                    </span>
                  </p>
                ) : (
                  <p>
                    {!user.language || user.language === "en"
                      ? "There may be "
                      : user.language === "it"
                      ? "Potrebbero esserci "
                      : user.language === "es"
                      ? "Puede haber "
                      : user.language === "fr"
                      ? "Il pourrait y avoir "
                      : "There may be "}
                    <strong>
                      {!user.language || user.language === "en"
                        ? "confidential documents."
                        : user.language === "it"
                        ? "documenti confidenziali."
                        : user.language === "es"
                        ? "documentos confidenciales."
                        : user.language === "fr"
                        ? "documents confidentiels."
                        : "confidential documents."}
                    </strong>
                    {!user.language || user.language === "en"
                      ? " Are you sure you want to download all the listed files?"
                      : user.language === "it"
                      ? " Sei sicuro di voler scaricare tutti i file elencati?"
                      : user.language === "es"
                      ? " ¿Estás seguro de que quieres descargar todos los archivos listados?"
                      : user.language === "fr"
                      ? " Êtes-vous sûr de vouloir télécharger tous les fichiers répertoriés?"
                      : " Are you sure you want to download all the listed files?"}
                  </p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  <span className="text-white">Cancel</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleDownload}
                  disabled={downloading}
                >
                  <span className="text-white">
                    {!user.language
                      ? "Download"
                      : user.language === "en"
                      ? "Download"
                      : user.language === "it"
                      ? "Scarica"
                      : user.language === "es"
                      ? "Descargar"
                      : user.language === "fr"
                      ? "Télécharger"
                      : "Download"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CustomerProfile;
