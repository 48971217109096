import http from './base-api-service';






const resendActivation = (id) => http.get(`/resendActivation/${id}`)
const checkUserAdminActivation = () => http.get(`/checkUserAdminActivation`)
const userAccessFiles = (id) => {
    return http.get(`/userAccessFiles/${id}`)
      // .then(response => response.data)
  }




const externals = {

  resendActivation,
  userAccessFiles,
  checkUserAdminActivation
}


export default externals;
