import React, { useEffect, useState } from "react";
import axios from "axios";
import { logout } from "../../../services/users-service";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthStore";

import "./cabecera.css";

const Cabecera = () => {
  const [imageUrl, setImageUrl] = useState("");
  const { user, onUserChange } = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
// const baseUrl = "http://localhost:4001/api"
  useEffect(() => {
    const fetchRandomImage = async () => {
      try {
        const response = await axios.get(`${baseUrl}/cover`, {
          responseType: 'blob',
        });
        const imageObjectURL = URL.createObjectURL(response.data);
        setImageUrl(imageObjectURL);
      } catch (error) {
        console.error("Error fetching the image from API", error);
      }
    };

    fetchRandomImage();
  }, []);

  async function handleLogout() {
    await logout();
    onUserChange(undefined);
    localStorage.clear();

    // history.push('/login');
  }

  return (
    <div className="cabecera-container">
      <header className="cabecera-header">
        <div className="cabecera-logo">
          <h1>PALLISER MENORCA</h1>
        </div>
        <nav className="cabecera-nav">
          <ul>
            <li>
              <a
                href="http://palliser.es"
                target="_blank"
                rel="noopener noreferrer"
                className="custom-link"
              >
                PALLISER LAW FIRM
              </a>
            </li>
            <li>
              <a
                href="http://menorcaadvance.com"
                target="_blank"
                rel="noopener noreferrer"
                className="custom-link"
              >
                MENORCA ADVANCE
              </a>
            </li>

            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm('Are you sure you want to log out?')) {
                    handleLogout();
                  }
                }}
                className="custom-link"
              >
                LOG OUT
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <hr className="cabecera-divider" />
      <div className="cabecera-content">
        <h2 className="cabecera-title">PALLISER MENORCA</h2>
        <p className="description">
          Your trusted partner in Menorca
          <br />
          Backed by Palliser & part of Menorca Advance Hub
        </p>
        {imageUrl && (
          <div className="cabecera-image-container" style={{position: 'relative'}}>
            <img src={imageUrl} alt="Menorca" className="cabecera-image" />
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              backgroundColor: 'white',
              opacity: 0.3
            }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Cabecera;
