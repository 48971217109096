import http from './base-api-service';



export const login = (email, password, totp) => http.post(`/login`, { email, password, totp })

export const code = (email, password) => http.post(`/code`, { email, password })


export const totp = (email, totp) => http.post(`/totp`, { email, totp })

export const profile = () => http.get('/users/me')

export const register = (user) => http.post('/users', user)




export const logout = async () => {
  try {
    localStorage.removeItem('token');
    await http.post('/logout');
  } catch (error) {
    // Manejar el error
    console.error(error);
  }
};




const update = (user) => http.put(`/user/${user.id}`, user)

const remove = (id) => {
  return http.delete(`/user/${id}`)
    .then(response => Promise.resolve())
}


// const userSettings = (id, query) => http.put(`/userSettings/${id}}`)
const get = (id) => {
  return http.get(`/users/${id}`)
  // .then(response => response.data)
}


// const userSettings = ('604482645df3e3a68ac81077', view) =>  http.put(`/userSettings/${id}?view=${view}`)



const userSettings = (view, state) => http.put(`/settings/?${view}=${state}`)

const list = () => {
  return http.get('/users')
}

const activeUsers = () => {
  return http.get('/activeusers')
}

const adminactivate = (id, state) => {
  return http.get(`/adminactivation/${id}/?activation=${state}`)
}

const language = (id, lang) => {
  return http.get(`/userLang/${id}/?lang=${lang}`)
}


const color = (settings) => {
  http.put(`/color/`, settings)
  console.log(`color dins service: ${settings.color}`)
}

const elevation = (settings) => {
  http.put(`/elevation/`, settings)
}

const font = (settings) => {
  http.put(`/font/`, settings)
}

const border = (settings) => {
  http.put(`/border/`, settings)
  console.log(`border dins service: ${settings.border}`)
}


const user = {
  login,
  register,
  list,
  activeUsers,
  get,
  userSettings,
  update,
  remove,
  adminactivate,
  color,
  elevation,
  font,
  border,
  totp,
  code,
  language
}

export default user;